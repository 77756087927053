import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import AppRoutes from "AppRoutes.json";
import useAccessToken from "hooks/useAccessToken";
import { useCallback, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAboutModal } from "../../hooks/CommandCenter/useAboutModal";
import { Resources } from "../../resources/Resources";
import { Icon } from "../Icon/Icon";
import "./Sidebar.scss";

type Props = {
  overlay: boolean;
  appMenu: any[];
  className?: string;
  updateMenu: boolean;
};

export const Sidebar = (props: Props) => {
  const { overlay, className, appMenu, updateMenu } = props;
  const [expanded, setExpanded] = useState(false);
  const { toggleAboutModal } = useAboutModal();
  const toggleSidebar = () => {
    document.documentElement.style.setProperty("--expanded-sidebar", expanded ? "0" : "1");
    setExpanded(!expanded)
  };
  const { hasAccessLevel } = useAccessToken();
  const findAccessLevel = useCallback((path) => AppRoutes.restricted.find(r => r.path === path)?.accessLevel!, [AppRoutes]);

  const sidebarItems = useMemo(() => {
    const routes = [];
    routes.push(appMenu.map((item, index) => {
      return (hasAccessLevel(findAccessLevel(item.path)) && ((item.beta === true && item.enabled) || !item.beta)) ? <NavLink
        key={item.title}
        className="SidebarItem"
        exact={true}
        to={item.path}
        activeClassName="active"
      >
        <li key={index}>
          <Icon name={item.icon} />
          {expanded ? <span className="SidebarItemText">{item.title}</span> : null}
        </li>
      </NavLink> : null
    }));

    return routes;
  }, [expanded, appMenu, updateMenu])

  return (
    <div key={`${expanded}-${updateMenu}`} className={className}>
      <div className={expanded ? "container-sidebar-expanded" : "container-sidebar"}>
        <nav className={`Sidebar ${expanded ? "expanded" : ""} ${overlay ? "overlay" : ""}`}>
          <ul className="SidebarItems">
            {sidebarItems}
            <li className={`SidebarItem ExpandIcon ${expanded ? "expanded" : ""}`} onClick={toggleSidebar}>
              {expanded ?
                <ArrowBackIosIcon />
                :
                <ArrowForwardIosOutlinedIcon />}
            </li>
            <div className={`LastItemBottom`}>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  toggleAboutModal();
                }}
                className={`SidebarItem  ${expanded ? "expanded" : ""}`}
              >
                <li className={`SidebarItem item-left ${expanded ? "expanded" : ""}`}>
                  <Icon name="info-filled" />
                  {expanded ?
                    <span className="SidebarItemText">
                      {Resources.get("Sidebar", "about", "label")}
                    </span>
                    : null}
                </li>
              </div>
              <NavLink to="/logout" className={`SidebarItem ${expanded ? "expanded" : ""}`}>
                <li>
                  <Icon name="logout" />
                  {expanded ?
                    <span className="SidebarItemText">
                      {Resources.get("Sidebar", "logout", "label")}
                    </span>
                    : null}
                </li>
              </NavLink>
            </div>
          </ul>
        </nav>
      </div>
    </div>
  );
}