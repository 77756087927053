import axios from "axios";
import { Endpoints } from "../config/endpoints";
import { EncryptHelper } from "../helpers/EncryptHelper";
import { AuthenticationResult, EncryptedDataResponse } from "../models/AuthenticationResult";
import { HttpInnerResponse } from "../models/HttpInnerResponse";

const IdentityServiceEndpoint = Endpoints.getEndpoint("REACT_APP_IDENTITY_ENDPOINT");

export type PortalType = "CC" | "MSERIES";

interface ILoginService {
  loginWithPortal(portal: PortalType, username: string, password: string): Promise<AuthenticationResult>;
  login(username: string, password: string): Promise<AuthenticationResult>;
  sendVerificationCode(email: string, retry?: boolean): Promise<EncryptedDataResponse>;
  authenticateSSOUser(ssoCookie: string): Promise<AuthenticationResult>;
}

export const LoginService: ILoginService = {

  loginWithPortal: async (portal: PortalType, username: string, password: string): Promise<AuthenticationResult> => {
    const payload: string = JSON.stringify({
      login: username,
      password: password,
      portal: portal
    });

    const message = EncryptHelper.encrypt(payload);

    const { data } = await axios.post<HttpInnerResponse>(
      IdentityServiceEndpoint + "security/authenticateWithPortal",
      { message }
    );

    return {
      statusCode: data.statusCode!,
      // @ts-ignore
      accessToken: data.payload.user.accessToken,
      // @ts-ignore
      idToken: data.payload.user.data
    } as AuthenticationResult;
  },

  login: async (username: string, password: string): Promise<AuthenticationResult> => {
    const { data } = await axios.post<HttpInnerResponse>(
      IdentityServiceEndpoint + "security/authenticate",
      {
        login: username,
        password: password,
      }
    );
    return {
      statusCode: data.statusCode,
      // @ts-ignore
      accessToken: data.payload.user.accessToken,
      // @ts-ignore
      idToken: data.payload.user.data
    } as AuthenticationResult;
  },

  sendVerificationCode: async (email: string): Promise<EncryptedDataResponse> => {
    const endpointUrl = "user/sendCode"
    const { data } = await axios.post( IdentityServiceEndpoint + endpointUrl ,{ email } );
    return data
  },

  authenticateSSOUser: async (ssoCookie: string): Promise<AuthenticationResult> => {
    const { data }: any = await axios.post(`${IdentityServiceEndpoint}security/authenticateSSOUser/${ssoCookie}`);
    if (!data) return null!;
    return {
      statusCode: 200,
      accessToken: data.payload.user.accessToken,
      idToken: data.payload.user.data,
    } as AuthenticationResult;
  }
};
